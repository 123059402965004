.collegecraft-page-holder {
  height: 100vh;
  position: relative;
  top: 0px;
  padding: 2.5rem;
  background: var(--Blue-gray-200, #d5d9eb) !important;
  overflow: auto !important;
  scroll-behavior: smooth;
  font-size: 13px;
  width: calc(100vw - 240px);

  h2 {
    font-size: 36px;
  }
}
