@import "../../Variables.scss";

.logged-in-user-profilepage-section{
  .user-loggedin-details-section {
    margin-left: 11rem !important;
    margin-top: -6rem !important;
  }
  
  .user-profile-page-section {
    margin-bottom: 2.786rem;
  }
  
  .email-header-section {
    .email-header {
      color: var(--Gray-500, #667085);
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  
  .loggedin-username-section {
    margin-bottom: 0.186rem;
  
    .loggedin-username {
      color: var(--Gray-900, #101828);
      font-family: $font-family;
      font-size: 30px;
      font-style: normal;
      font-weight: 550;
      line-height: 30px;
    }
  }
  
  .update-personal-info-section {
    width: 100%;
    display: flex;
  
    .personal-info-first-section {
      width: 24%;
      background: rgb(249, 248, 248);
  
      .personal-info-header {
        color: var(--Gray-900, #101828);
        font-family: $font-family;
        font-size: 18px;
        font-style: normal;
        font-weight: bold;
        line-height: 30px;
      }
  
      .personal-info-update-details-header {
        color: var(--Gray-500, #667085);
        font-family: $font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  
    .personal-info-second-section {
      width: 75%;
  
      .Mui-error {
        color: #d51923;
        margin-left: 0rem;
        border: none !important;
      }
  
      .MuiInputBase-input {
        height: 0.28rem;
        border-radius: 0.5rem;
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--White, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
  
      .MuiOutlinedInput-notchedOutline {
        border: none !important;
      }
  
      .MuiOutlinedInput-input:focus {
        border-radius: 0.5rem;
        border: 1px solid var(--Purple-500, #5841d9);
        background: var(--White, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
  
      [class$="MuiInputBase-input-MuiOutlinedInput-input"]:focus {
        border-radius: 0.5rem;
        border: 1px solid var(--Purple-500, #5841d9);
        background: var(--White, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
  
      .personal-section-box-shadow {
        width: 100%;
        max-height: 100%;
        background-color: #fff;
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
        padding: 20px;
        border-radius: 10px;
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
      }
  
      .personal-info-first-lastname-section {
        display: flex;
        width: 100%;
        margin-bottom: 0.786rem;
  
        .personal-info-first-name-section {
          margin-right: 0.786rem;
          width: 50%;
          height: 70px;
  
          .personal-info-firstname-header {
            color: var(--Gray-700, #344054);
            font-family: $font-family;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
  
          .personal-info-firstname-textfield {
            height: 2rem;
            margin-top: 0.3rem;
  
            .MuiInputBase-input {
              height: 0.2rem;
              color: var(--Gray-500, #101828) !important;
              font-family: $font-family;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
  
        .personal-info-last-name-section {
          width: 50%;
          height: 70px;
  
          .personal-info-lastname-header {
            color: var(--Gray-700, #344054);
            font-family: $font-family;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
  
          .personal-info-lastname-textfield {
            height: 2rem;
            margin-top: 0.3rem;
  
            .MuiInputBase-input {
              height: 0.2rem;
              color: var(--Gray-500, #101828) !important;
              font-family: $font-family;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }
  
      .personal-info-email-section {
        height: 70px;
        margin-bottom: 0.786rem;
  
        .personal-info-email-header {
          color: var(--Gray-700, #344054);
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
  
        .personal-info-email-field {
          height: 2rem;
          margin-top: 0.3rem;
  
          .MuiInputBase-input {
            height: 0.2rem;
            color: var(--Gray-500, #101828) !important;
            font-family: $font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
  
      }
  
      .personal-info-username-section {
        height: 70px;
        margin-bottom: 1rem;
  
        .personal-info-username-header {
          color: var(--Gray-700, #344054);
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
  
        .personal-info-username-field {
          height: 2rem;
          margin-top: 0.3rem;
  
          .MuiInputBase-input {
            height: 0.2rem;
            color: var(--Gray-500, #101828) !important;
            font-family: $font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
  
      .avatar-header{
       
        color: var(--Gray-700, #344054) !important;
        font-family: $font-family;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 20px !important;
        position: relative !important;
        bottom: 10px !important;
    
      }
  
      .personal-info-action-btn-sections {
        display: flex;
        justify-content: flex-end;
        text-align: end !important;
        gap: 8px;
        position: relative;
        flex-wrap: wrap;
  
        .personal-info-cancel-btn-section {
          border: 1px solid rgb(210, 208, 208);
          color: var(--Gray-700, #344054);
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          text-transform: none;
          line-height: 20px;
          border-radius: 8px;
          border: 1px solid var(--Gray-300, #d0d5dd);
          background: var(--White, #fff);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          padding: 10px 16px;
  
        }
  
        .personal-info-cancel-btn-section:hover {
          border-radius: 8px;
          border: 1px solid var(--Brand-600, #62c3e0);
        }
  
        .personal-info-save-btn-section {
  
          color: var(--Gray-700, white);
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          text-transform: none;
          line-height: 20px;
          border-radius: 8px;
          border: 1px solid var(--Brand-600, #7f56d9);
          background: var(--Brand-600, #7f56d9);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          padding: 10px 16px;
  
  
        }
  
        .personal-info-save-btn-section:hover {
          color: var(--Gray-700, white);
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          text-transform: none;
          line-height: 20px;
          border-radius: 8px;
          border: 1px solid var(--Brand-600, #7f56d9);
          background: var(--Purple-800, #4a1fb8);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          padding: 10px 16px;
  
        }
  
        .personal-info-savedisabled-btn-section {
          border-radius: 8px !important;
          border: 1px solid var(--Gray-600, #475467) !important;
          background: var(--Gray-700, #344054) !important;
          color: var(--Gray-700, white);
          font-family: $font-family;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 600 !important;
          text-transform: none !important;
          line-height: 20px !important;
          padding: 10px 16px !important;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
          text-transform: none !important;
        }
      }
    }
  }
  
  .personal-info-divider {
    margin-top: 15px !important;
    margin-bottom: 15px;
  }
  
  .profile-page-scroll-section {
    height: calc(100vh - 250px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  
  .update-profile-info-section {
    width: 100%;
    display: flex;
  
    .profile-info-first-section {
      width: 24%;
      background: rgb(249, 248, 248);
  
      .profile-info-header {
        color: var(--Gray-900, #101828);
        font-family: $font-family;
        font-size: 18px;
        font-style: normal;
        font-weight: bold;
        line-height: 30px;
      }
  
      .profile-info-update-details-header {
        color: var(--Gray-500, #667085);
        font-family: $font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  
    .profile-info-second-section {
      width: 75%;
  
      .Mui-error {
        color: #d51923;
        margin-left: 0rem;
        border: none !important;
      }
  
      .profile-section-box-shadow {
        width: 100%;
        max-height: 100%;
        background-color: #fff;
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
        padding: 20px;
        border-radius: 10px;
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
      }
  
      .profile-toggle-section {
        margin-top: 10% !important;
        color: var(--Gray-900, #101828);
        font-family: $font-family;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
  
        .Mui-checked {
          color: #7f56d9 !important;
        }
      }
  
      .profile-info-password-section {
        height: 70px;
        margin-bottom: 0.786rem;
  
        .profile-info-password-header {
          color: var(--Gray-700, #344054);
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
  
        .profile-info-password-field {
          height: 2rem;
          margin-top: 0.3rem;
  
          .MuiInputBase-input {
            height: 0.2rem;
            color: var(--Gray-500, #101828) !important;
            font-family: $font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
  
      .profile-info-country-section {
        height: 70px;
        margin-bottom: 0.786rem;
  
        .profile-info-country-header {
          color: var(--Gray-700, #344054);
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
  
        .profile-info-country-field {
          height: 2rem;
          margin-top: 0.3rem;
          border-radius: 0.5rem;
  
          .MuiInputBase-input {
            height: 0.28rem;
            background: var(--White, #fff);
            color: #344054 !important;
          }
  
          .Mui-focused {
            border-radius: 0.5rem;
            border: 1px solid var(--Purple-500, #5841d9);
            background: var(--White, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: #344054;
  
            .MuiOutlinedInput-notchedOutline {
              border-radius: 0.5rem;
              border: none;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
          }
        }
      }
  
      .profile-info-school-section {
        height: 70px;
        margin-bottom: 0.786rem;
  
        .profile-info-school-header {
          color: var(--Gray-700, #344054);
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
  
        .profile-info-school-field {
          height: 2rem;
          margin-top: 0.3rem;
  
          .MuiInputBase-input {
            height: 0.28rem;
            border-radius: 0.5rem;
            border: 1px solid var(--Gray-300, #d0d5dd);
            background: var(--White, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: #344054 !important;
          }
  
          .MuiOutlinedInput-notchedOutline {
            border: none !important;
          }
  
          .MuiOutlinedInput-input:focus {
            border-radius: 0.5rem;
            border: 1px solid var(--Purple-500, #5841d9);
            background: var(--White, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: #344054 !important;
          }
  
          [class$="MuiInputBase-input-MuiOutlinedInput-input"]:focus {
            border-radius: 0.5rem;
            border: 1px solid var(--Purple-500, #5841d9);
            background: var(--White, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }
      }
  
      .profile-info-zipcode-section {
        height: 70px;
        margin-bottom: 0.786rem;
  
        .profile-info-zipcode-header {
          color: var(--Gray-700, #344054);
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
  
        .profile-info-zipcode-field {
          height: 2rem;
          margin-top: 0.3rem;
  
          .MuiInputBase-input {
            height: 0.28rem;
            border-radius: 0.5rem;
            border: 1px solid var(--Gray-300, #d0d5dd);
            background: var(--White, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
  
          .MuiOutlinedInput-notchedOutline {
            border: none !important;
          }
  
          .MuiOutlinedInput-input:focus {
            border-radius: 0.5rem;
            border: 1px solid var(--Purple-500, #5841d9);
            background: var(--White, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
  
          [class$="MuiInputBase-input-MuiOutlinedInput-input"]:focus {
            border-radius: 0.5rem;
            border: 1px solid var(--Purple-500, #5841d9);
            background: var(--White, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }
      }
  
      .profile-info-graduateYear-section {
        height: 70px;
        margin-bottom: 0.786rem;
        border-radius: 0.5rem;
  
        .profile-info-graduateYear-header {
          color: var(--Gray-700, #344054);
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
  
        .profile-info-graduateYear-field {
          height: 2rem;
          margin-top: 0.3rem;
          border-radius: 0.5rem;
  
          .Mui-focused {
            border: 1px solid var(--Purple-500, #5841d9);
            background: var(--White, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  
            .MuiOutlinedInput-notchedOutline {
              border: none;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
          }
  
          .MuiInputBase-input {
            height: 0.2rem;
            color: var(--Gray-500, #101828) !important;
            font-family: $font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
  
      .profile-info-ethnicity-section {
        height: 70px;
        margin-bottom: 0.786rem;
  
        .profile-info-ethnicity-header {
          color: var(--Gray-700, #344054);
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
  
        .profile-info-ethnicity-field {
          height: 2rem;
          margin-top: 0.3rem;
          border-radius: 0.5rem;
  
          .Mui-focused {
            border: 1px solid var(--Purple-500, #5841d9);
            background: var(--White, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  
            .MuiOutlinedInput-notchedOutline {
              border: none;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
          }
  
          .MuiInputBase-input {
            height: 0.2rem;
            color: var(--Gray-500, #101828) !important;
            font-family: $font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
  
      .profile-info-gender-section {
        height: 70px;
        margin-bottom: 0.786rem;
  
        .profile-info-gender-header {
          color: var(--Gray-700, #344054);
          font-family: $font-family;
          font-size: 14px !important;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
  
        .profile-info-gender-field {
          height: 2rem;
          margin-top: 0.3rem;
          border-radius: 0.5rem;
  
          .Mui-focused {
            border: 1px solid var(--Purple-500, #5841d9);
            background: var(--White, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  
            .MuiOutlinedInput-notchedOutline {
              border: none;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
          }
  
          .MuiInputBase-input {
            height: 0.2rem;
            color: var(--Gray-500, #101828) !important;
            font-family: $font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
  
  
      .profile-info-state-section {
        height: 70px;
        margin-bottom: 0.786rem;
  
        .profile-info-state-header {
          color: var(--Gray-700, #344054);
          font-family: $font-family;
          font-size: 14px !important;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
  
        .profile-info-state-field {
          height: 2rem;
          margin-top: 0.3rem;
          border-radius: 0.5rem;
  
          .Mui-focused {
            border: 1px solid var(--Purple-500, #5841d9);
            background: var(--White, #fff);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  
            .MuiOutlinedInput-notchedOutline {
              border: none;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
          }
  
          .MuiInputBase-input {
            height: 0.2rem;
            color: var(--Gray-500, #101828) !important;
            font-family: $font-family;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }
  
  .profile-info-action-btn-sections {
    display: flex;
    justify-content: end;
    text-align: end !important;
    gap: 10px;
    position: relative;
    flex-wrap: wrap;
    right: 19px;
  
    .profile-info-cancel-btn-section {
      border: 1px solid rgb(210, 208, 208);
      color: var(--Gray-700, #344054);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-transform: none;
      line-height: 20px;
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--White, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 10px 16px;
  
    }
  
    .profile-info-save-btn-section {
      color: var(--Gray-700, white);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-transform: none;
      line-height: 20px;
      border-radius: 8px;
      border: 1px solid var(--Brand-600, #7f56d9);
      background: var(--Brand-600, #7f56d9);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 10px 16px;
  
    }
  
    .profile-info-savedisabled-btn-section {
      border-radius: 8px !important;
      border: 1px solid var(--Gray-600, #475467) !important;
      background: var(--Gray-700, #344054) !important;
      color: var(--Gray-700, white) !important;
      font-family: $font-family;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      text-transform: none !important;
      line-height: 20px !important;
      padding: 10px 16px !important;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
      text-transform: none !important;
    }
  
    .profile-info-save-btn-section:hover {
      color: var(--Gray-700, white);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-transform: none;
      line-height: 20px;
      border-radius: 8px;
      border: 1px solid var(--Brand-600, #7f56d9);
      background: var(--Purple-800, #4a1fb8);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 10px 16px;
    }
  
    .profile-info-delete {
      margin-top: 0.286rem;
      color: var(--Gray-900, #101828) !important;
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: 24px;
      text-decoration-line: underline;
      cursor: pointer !important;
    }
  }

 
  
  .cancel-btn-in-delete-popup {
    margin-right: 0.786rem !important;
    border: 1px solid rgb(210, 208, 208);
    color: var(--Gray-700, #344054) !important;
    font-family: $font-family;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    text-transform: none !important;
    line-height: 20px;
    height: 32.786px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #d0d5dd) !important;
    background: var(--White, #fff) !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
  }
  
  .delete-btn-in-delete-popup {
    color: #ffffff !important;
    font-size: medium !important;
    text-transform: none !important;
    font-family: $font-family;
    line-height: 20px;
    height: 32.786px;
    border-radius: 8px;
    color: var(--Gray-700, #344054);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: none;
    background-color: #7f56d9 !important;
  
    line-height: 20px;
    height: 32.786px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin-right: 10px !important;
  }
  
  .delete-pop-up-btns {
    text-align: end !important;
  }
  
  .user-avatar-section{
    height: 116px !important;
  }
  
  .avatar-section{
    margin-bottom: 1.386rem;
  }
  
  .first-genaration-label-text{
    color: var(--Gray-700, #344054) !important ;
    font-family: $font-family;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
  }
}


.profile-info-delete-popup-action-btn-sections {
  display: flex;
  justify-content: end;
  text-align: end !important;
  gap: 10px;
  position: relative;
  flex-wrap: wrap;
  right: 19px;

  .profile-info-delete-popup-cancel-btn-section {
    border: 1px solid rgb(210, 208, 208);
    color: var(--Gray-700, #344054);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: none;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 16px;

  }

  .profile-info-delete-popup-save-btn-section {
    color: var(--Gray-700, white);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: none;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid var(--Brand-600, #7f56d9);
    background: var(--Brand-600, #7f56d9);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 16px;

  }



  .profile-info-delete-popup-save-btn-section:hover {
    color: var(--Gray-700, white);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: none;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid var(--Brand-600, #7f56d9);
    background: var(--Purple-800, #4a1fb8);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 16px;
  }

 
}



