@import "../../Variables.scss";

.user-search-history-page-content-sections{

  .user-search-history-page-first-section{
    margin-bottom:6.786rem ;
  }
 

  .user-search-history-page-second-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
  
    .user-search-history-page-header-section {
      color: #101828;
       font-family: $font-family;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 38px;
      flex: 1;
    }
  
    .user-search-history-action-buttons {
      display: flex;
      justify-content: flex-end;
      flex: 1; 
      gap: 1rem; 
  
      .user-search-history-page-export-button-section,
      .user-search-history-page-savepdf-button-section,
      .user-search-history-page-clear-button-section,.user-search-history-page-shareemail-button-section {
        display: flex;
        align-items: center;
      }

      .active-export-button-header{
        position: relative;
        top: -6px !important;
      }
  
      .export-button{
        border-radius: 8px;
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--White, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--Gray-700, #344054);
         font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding: 11px 16px;
        height: 42px;
        text-decoration: none !important;
        text-transform: none !important;
      }
      .savepdf-button {
        border-radius: 8px;
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--White, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--Gray-700, #344054);
         font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding: 12px 16px;
        height: 42px;
        text-decoration: none !important;
        text-transform: none !important;
      }
      .shareemail-button {
        border-radius: 8px;
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--White, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--Gray-700, #344054);
         font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding: 12px 16px;
        height: 42px;
        text-decoration: none !important;
        text-transform: none !important;
      }

      .clear-all-button {
        color: var(--Gray-700, white);
         font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-transform: none;
        line-height: 20px;
        border-radius: 8px;
        border: 1px solid var(--Brand-600, #7f56d9);
        background: var(--Brand-600, #7f56d9);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding: 10px 16px;
      }

      .clear-all-button:hover {
        border-radius: 8px;
        border: 1px solid var(--Brand-600, #7f56d9);
        background: var(--Purple-800, #4a1fb8);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding: 10px 16px;
      }
  
      .export-button:hover,
      .savepdf-button:hover,.shareemail-button:hover{
        border: 1px solid var(--Brand-600, #62c3e0);
      }
  

      .savepdf-button-disabled,.export-button-disabled, .sharemail-button-disabled{
        border-radius: 8px !important;
        border: 1px solid var(--Gray-600, #475467) !important;
        background: var(--Gray-700, #344054) !important;
        color: var(--Gray-700, white);
         font-family: $font-family;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        text-transform: none !important;
        line-height: 20px !important;
        padding: 10px 16px !important;     
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
        text-transform: none !important;
      }

      .clear-all-button-disabled{
        border-radius: 8px !important;
         border: 1px solid var(--Gray-600, #475467) !important;
         background: var(--Gray-700, #344054) !important;
         color: var(--Gray-700, white);
          font-family: $font-family;
         font-size: 14px;
         font-style: normal;
         font-weight: 600;
         text-transform: none;
         line-height: 20px;
         padding: 10px 16px;
         box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
         text-transform: none !important;
       }
  
      .export-button-image{
        position: relative !important;
        right: 4px;
      }

      .shareemail-button-image{
        position: relative !important;
        right: 4px;
      }
      

      .savepdf-button-image {
        position: relative !important;
        right: 4px;
      }
  
      .export-button-disabled-image,
      .savepdf-button-disabled-image, .shareemail-button-disabled-image{
        position: relative !important;
        right: 3px;
        filter: brightness(0) invert(1);
      }
    }
  }
  
  
  .search-historypage-scroll-section {
    height: calc(100vh - 270px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    background-color: #fff;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;

    .all-search-historyheader {
      color: var(--Gray-900, #101828) !important;
       font-family: $font-family;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
    
    .search-historygrid-section {
    
      .css-pdct74-MuiTablePagination-selectLabel {
        color: var(--Gray-700, #667085) !important;
         font-family: $font-family;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 20px !important;
      }
    }
    
    .column-headers {
      color: var(--Gray-500, #667085) !important;
       font-family: $font-family;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
     
      height: 44px !important;
      padding: 12px 24px;
      align-items: center;
      gap: 12px;
      align-self: stretch;
      border-bottom: 1px solid var(--Gray-200, #eaecf0) !important;
      background: var(--Gray-50, #f9fafb) !important;
    }
    
  .main-row-alignment {
    font-family: $font-family;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-top: 1px solid rgba(224, 224, 224, 1) !important;
    text-align: left;
    padding: 16px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .search-name {
    color: var(--Gray-900, #101828) !important;
     font-family: $font-family;
    font-size: 14.786px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 20px;
  }
  
  .search-description {
     font-family: $font-family;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px;
    color: var(--Gray-500, #667085) !important;
  }
  
  .date-searched {
    color: var(--Gray-500, #667085);
     font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px;
  }
  
  .tags-align {
    word-wrap: break-word !important;
    color: var(--Gray-500, #667085) !important;
     font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px;
  }
  
  .search-history-pagination-aligh {
    color: var(--Gray-700, #344054) !important;
     font-family: $font-family;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 20px !important;
  }
  
} 
  }
  


.search-historyclear-section-action-btns {
  display: flex;
  justify-content: end;
  text-align: end !important;
  gap: 10px;
  position: relative;
  flex-wrap: wrap;
  right: 19px;

  .search-historyclear-cancel-btn-section {
    border: 1px solid rgb(210, 208, 208);
    color: var(--Gray-700, #344054);
     font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: none;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 16px;

  }

  .search-historyclear-clear-btn-section {
    color: var(--Gray-700, white);
     font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: none;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid var(--Brand-600, #7f56d9);
    background: var(--Brand-600, #7f56d9);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 16px;

  }
}