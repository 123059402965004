@import "../../Variables.scss";

.left-naviagtion-section {
  height: 100vh !important;
  width: 240px !important;
}

.college-application-nav-header {
  display: flex !important;
  top: 10px !important;
  position: fixed !important;
  left: 15px !important;
  width: 16px;
  height: 41.08px;
  padding: 7.988px 8.049px 9.092px 1.141px;
  align-items: flex-start;
  gap: 5.706px;
  flex-shrink: 0;

  .collegecraft-nav-headername {
    color: #fff;
    font-family: $font-family;
    font-size: 19.49px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
}

.MuiDrawer-paper {
  box-sizing: border-box !important;
  width: 240px !important;
  background: #141627 !important;
}

.left-navigation-content {
  height: calc(100vh - 100px) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  flex-direction: column;

  .left-navigation-first-section {
    .menu-item {
      border-radius: 6px;
      width: 200px;
      margin-left: 20px;
      height: 3rem;
      margin-bottom: 10px;
    }

    .menu-item:hover {
      border-radius: 6px;
      width: 200px;
      margin-left: 20px;
      height: 3rem;
      margin-bottom: 10px;
    }

    .menu-item-active {
      border-radius: 6px;
      background: rgba(105, 65, 198, 0.35) !important;
      width: 200px;
      margin-left: 20px;
      height: 3rem;
      margin-bottom: 10px;
    }

    .find-majors-header,
    .find-mentors-header,
    .find-colleges-header,
    .find-scholarship-header {
      color: var(--Brand-100, #f4ebff);
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      
    }

    .threelayers-icon,
    .college-icon,
    .mentors-icon,
    .scholarship-icon {
      margin-right: -1.2rem;
    }
  }

  .left-navigation-second-section {
    .menu-item {
      border-radius: 6px;
      width: 200px;
      margin-left: 20px;
      height: 3rem;
      margin-bottom: 10px;
    }

    .menu-item:hover {
      border-radius: 6px;
      width: 200px;
      margin-left: 20px;
      height: 3rem;
      margin-bottom: 10px;
    }

    .menu-item-active {
      border-radius: 6px;
      background: rgba(105, 65, 198, 0.35) !important;
      width: 200px;
      margin-left: 20px;
      height: 3rem;
      margin-bottom: 10px;
    }

    .my-search-alignment {
      color: var(--Brand-100, #f4ebff);
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-left: 18px !important;
      margin-bottom: 5px !important;
    }

    .nav-add-btn-section {
      display: flex;
      width: 210px;
      justify-content: center !important;
      align-items: center !important;
      margin-left: 1rem;
      gap: 8px;
      margin-bottom: 13rem !important;

      .add-btn-section-disabled {
        border-radius: 8px;
        border: 1px solid var(--Gray-600, #475467);
        background: var(--Gray-700, #344054);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      .add-btn-section {
        border-radius: 8px;
        border: 1px solid var(--Brand-600, #7f56d9);
        background: var(--Brand-600, #7f56d9);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      .add-btn-section:hover {
        border-radius: 8px;
        border: 1px solid var(--Brand-600, #7f56d9);
        background: var(--Purple-800, #4a1fb8);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      }

      .add-btn-header {
        color: var(--Gray-300, #d0d5dd);
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        text-transform: none !important;
      }
    }
  }

  .left-navigation-third-section {
    .menu-item {
      border-radius: 6px !important;
      width: 200px !important;
      margin-left: 20px !important;
      height: 3rem;
      margin-bottom: 10px;
    }

    .menu-item:hover {
      border-radius: 6px !important;
      width: 200px;
      margin-left: 20px;
      height: 3rem;
      margin-bottom: 10px;
    }

    .menu-item-active {
      border-radius: 6px;
      background: rgba(105, 65, 198, 0.35) !important;
      width: 200px;
      margin-left: 20px;
      height: 3rem;
      margin-bottom: 10px;
    }

    .settings-header,
    .favorites-header,
    .logout-header {
      color: var(--Brand-100, #f4ebff);
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .settings-icon,
    .lock-icon,
    .star-icon {
      margin-right: -1.2rem;
    }

    .avatar-section {
      display: flex !important;
      margin-left: 0.5rem;
      margin-bottom: -0.786rem !important;
      .avatar-image {
        display: inline-flex !important;
        flex-shrink: 0;
        width: 30px !important;
        height: 30px !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        top: -3px !important;
        left: -5px !important;
        position: relative !important;
      }

      .avatar-header-section {
        .avatar-header-text{
          color: var(--Brand-100, #f4ebff);
          font-family: $font-family;
          font-size: 16px;
          font-style: normal;
          font-weight: 700 !important;
          line-height: 24px;
        }
       
      }
    }
  }
}

.divider-element {
  background: #292f5d !important;
  width: 239px;
  height: 1.5px;
  margin-bottom: 10px !important;
}

.settingsDrawer {
  .menu-item {
    border-radius: 6px;
    width: 200px;
    margin-left: 20px;
    height: 3rem;
    margin-bottom: 10px;
  }

  .menu-item:hover {
    border-radius: 6px;
    width: 200px;
    margin-left: 20px;
    height: 3rem;
    margin-bottom: 10px;
  }

  .menu-item-active {
    border-radius: 6px;
    background: rgba(105, 65, 198, 0.35) !important;
    width: 200px;
    margin-left: 20px;
    height: 3rem;
    margin-bottom: 10px;
  }
}

.settings-section {
  width: 100% !important;
  height: 20.68px;
  flex-shrink: 0;
  margin-left: 0rem;
  margin-top: 12px;
  margin-bottom: 1rem !important;

  .settings-btn-sec {
    color: #fff !important;
    font-family: $font-family;
    font-size: 19.49px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none !important;
  }
}

.profile-header,
.favorites-header,
.search-header {
  color: var(--Brand-100, #f4ebff);
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.user-icon,
.search-icon,
.star-icon {
  margin-right: -1.2rem;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background: none !important;
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}

.added-favorites-section {
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  width: 34px !important;
  border-radius: 16px;
  margin-right: 1.786rem;
  background: var(--Lime, #e6fab5) !important;

  .favorites-count {
    color: #141627;
    text-align: center;
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 550;
    margin-top: 1px;
    line-height: 20px;
  }
}

.majors-page {
  text-align: center !important;
  margin-left: 18rem;
  margin-top: 10rem !important;
}

.display-flex {
  display: flex !important;
}