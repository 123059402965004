@import "./Variables.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.input-element-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 82px !important;
  

  span {
    color: var(--Gray-700, #344054);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .input-element {
    display: flex;
    margin-top: 0px !important;
    margin-bottom: 15px !important;
    font-family: $font-family !important;

    .MuiInputBase-input {
      color: var(--Gray-500, #0e0f10) !important;
      height: 0.28rem;
      border-radius: 0.5rem;
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--White, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }

    .MuiOutlinedInput-input:focus {
      border-radius: 0.5rem;
      border: 1px solid var(--Purple-500, #5841d9);
      background: var(--White, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    [class$="MuiOutlinedInput-notchedOutline"] {
      border: none !important;
    }

    [class$="MuiInputBase-input-MuiOutlinedInput-input"]:focus {
      border-radius: 0.5rem;
      border: 1px solid var(--Purple-500, #5841d9);
      background: var(--White, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }

  .Mui-error {
    color: #d32f2f;
    margin-left: 0rem;
    justify-content: flex-start;
  }
}

// please don't remove the commented code
// .css-1laqsz7-MuiInputAdornment-root {
//   display: -webkit-box !important;
//   display: -webkit-flex !important;
//   display: -ms-flexbox !important;
//   display: flex !important;
//   height: 0.01em !important;
//   max-height: 2em !important;
//   -webkit-align-items: center !important;
//   -webkit-box-align: center !important;
//   -ms-flex-align: center !important;
//   align-items: center !important;
//   white-space: nowrap !important;
//   color: rgba(0, 0, 0, 0.54) !important;
//   margin-left: -54px !important;
// }


// .css-ja5taz-MuiTooltip-tooltip {
//   background-color: #ffffff !important;
//   border-radius: 12px !important;
//   border: 2px solid var(--Brand-600, #7f56d9) !important; 
//   padding: 4px 8px !important;
//   text-align: center;
//   align-items: center;
//   justify-content: center;
//   font-size:12px !important;
//   max-width: 300px !important;
//   margin: 2px !important;
//   word-wrap: break-word !important;
// }

// // sx={{
//   '& .MuiTooltip-tooltip': {
//     backgroundColor: '#ffffff',
//     borderRadius: '12px',
//     border: '2px solid var(--Brand-600, #7f56d9)',
//     padding: '4px 8px',
//     fontSize: '12px',
//     maxWidth: '300px',
//     wordWrap: 'break-word',
//   },
// }}

.custom-input-adornment {
  display: flex !important;
  height: 0.01em !important;
  max-height: 2em !important;
  align-items: center !important;
  white-space: nowrap !important;
  color: rgba(0, 0, 0, 0.54) !important;
  margin-left: -54px !important;
}


.f-custom-input-adornment {
  display: flex !important;
  height: 0.01em !important;
  max-height: 2em !important;
  align-items: center !important;
  white-space: nowrap !important;
  color: rgba(0, 0, 0, 0.54) !important;
  margin-left: -54px !important;
}
.fr-custom-input-adornment {
  display: flex !important;
  height: 0.01em !important;
  max-height: 2em !important;
  align-items: center !important;
  white-space: nowrap !important;
  color: rgba(0, 0, 0, 0.54) !important;
  margin-left: -54px !important;
}