@import "../../Variables.scss";

.header-section {
  text-align: justify !important;
  margin-bottom: 3rem;
}

.signup-form {
  width: 75%;

  .text-element {
    height: 2rem;
    margin-top: 0.2rem;

    .MuiInputBase-input {
      height: 0.2rem;
      color: var(--Gray-500, #667085);
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .signup-formelements-headers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1px !important;
    height: 65px;
    margin-top: 1rem;
    align-self: stretch;

    .Mui-error {
      color: #d32f2f;
      margin-left: 0rem;
    }

    .firstname-header,
    .lastname-header,
    .email-header,
    .password-header {
      color: var(--Gray-700, #344054);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .pwd-restrict-message {
    color: var(--Gray-500, #667085);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    align-self: stretch;
    margin-top: -0.5rem !important;
    margin-bottom: 0.3rem !important;
  }

  .create-btn-section {
    display: flex;
   margin-top: 1px !important;
    align-items: flex-start;
    margin-bottom: 8px !important;

    .create-btn {
      border-radius: 8px;
      border: 1px solid var(--Brand-600, #7f56d9);
      background: #4d41d9;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .create-btn-disabled {
      border-radius: 8px;
      border: 1px solid var(--Gray-600, #475467);
      background: var(--Gray-700, #344054);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .create-btn:hover {
      border-radius: 8px;
      border: 1px solid var(--Brand-600, #7f56d9);
      background: var(--Purple-800, #4a1fb8);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }

  .signup-btn-section {
    text-align: center;
    padding: 10px 0px;

    .signup-btn {
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--White, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      text-transform: none;
    }
  }

  .signup-btn-section-error{
    text-align: center;
    padding: 10px 0px;
    

    .error-signup-btn {
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--White, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      text-transform: none;
      
    }


    
  }

  .signup-btn-lable {
    color: var(--Gray-700, #344054);
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .signup-header-section {
    color: var(--Gray-900, #101828);
    font-family: $font-family;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.72px;
    margin-bottom: 20px !important;
  }

  .underline-link {
    text-decoration-line: underline;
  }

  .login-label {
    color: var(--Brand-700, #6941c6);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration-line: underline;
  }

  .already-account {
    color: var(--Gray-500, #667085);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

    .row-reverse {
      flex-direction: row-reverse;
    }

    .terms-link {
      color: var(--Brand-700, #6941c6) !important;
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      text-decoration-line: underline;
      cursor: po$font-family;
    }
  }

  .error-service-aggr-container {
    display: inline-flex;
    margin-top: 0.186rem;

    .error-service-aggr-checkbox {
      background: var(--White, #fff);
      width: 16px;
      color: #d32f2f !important;
      height: 16px;
      margin-right: 0.412rem;
      top: -1.712px;
      position: relative;
    }

    .Mui-checked {
      color: #d32f2f !important;
    }

    .error-service-aggr-text {
      color: #d32f2f !important;
      font-family: $font-family;
      font-style: normal;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      .error-terms-link {
        color: #d32f2f !important;
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-decoration-line: underline !important;
        cursor: pointer;
      }
    }
  }

  .service-aggr-container {
    display: inline-flex;
    margin-top: 0.186rem;

    .service-aggr-checkbox {
      background: var(--White, #fff);
      width: 16px;
      height: 16px;
      margin-right: 0.412rem;
      top: -1.712px;
      position: relative;
    }

    .Mui-checked {
      color: #6941c6 !important;
    }

    .service-aggr-text {
      color: var(--Gray-700, #344054);
      font-family: $font-family;
      font-style: normal;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      .terms-link {
        color: var(--Brand-700, #344054) !important;
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-decoration-line: underline !important;
        cursor: pointer;
      }
    }
  }

  .checkbox-alignment {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid var(--Gray-300, #d0d5dd) !important;
    background: var(--White, #fff) !important;
    margin-right: 0.786rem !important;
  }

  .copy-rights-section {
    display: flex;
    width: 100% !important;
    margin: 1rem 0 1rem 0;
    justify-content: space-between;
  }
}

.collegecraft-headername {
  color: #000;
  font-family: $font-family;
  font-size: 24.671px !important;
  font-style: normal;
  font-weight: 900 !important;
  line-height: normal;
  display: flex;
  height: 96px;
  padding: 38px;
  align-items: center;
  align-self: stretch;
}

.main-grid-section {
  width: 90% !important;
  margin-left: 4% !important;
  display: flex !important;
}

.form-mainbox-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2rem;
  float: left;
  margin-right: 6rem;
}


.signup-input-element-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 78px !important;
  

  span {
    color: var(--Gray-700, #344054);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .input-element {
    display: flex;
    margin-top: 0px !important;
    margin-bottom: 15px !important;
    font-family: $font-family;

    .MuiInputBase-input {
      color: var(--Gray-500, #0e0f10) !important;
      height: 0.28rem;
      border-radius: 0.5rem;
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--White, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }

    .MuiOutlinedInput-input:focus {
      border-radius: 0.5rem;
      border: 1px solid var(--Purple-500, #5841d9);
      background: var(--White, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    [class$="MuiOutlinedInput-notchedOutline"] {
      border: none !important;
    }

    [class$="MuiInputBase-input-MuiOutlinedInput-input"]:focus {
      border-radius: 0.5rem;
      border: 1px solid var(--Purple-500, #5841d9);
      background: var(--White, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }

  .Mui-error {
    color: #d32f2f;
    margin-left: 0rem;
    justify-content: flex-start;
  }
}

.signup-pwd-api-error-message{
  top: -17px !important;
  position: relative !important;

  
  .css-uhz803-MuiTypography-root {
    margin: 0 !important;
    font-family: $font-family;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #d32f2f !important;
}
}

.invitation-api-error-message{
  top: 1px !important;
  position: relative !important;

  
  .css-uhz803-MuiTypography-root {
    margin: 0 !important;
    font-family: $font-family;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #d32f2f !important;
}
}


.sign-up-custom-tooltip {
  background-color: #ffffff !important;
  border-radius: 10px !important;
  border: 1px solid var(--Brand-600, #7f56d9) !important;
  padding: 2px 6px !important;
  text-align: center;
  font-size: 12px !important;
  max-width: 400px !important;
  word-wrap: break-word !important;
}

.checkbox-signup-title{
  color: #344054 !important;
  font-family: $font-family;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}