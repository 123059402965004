@import "../../Variables.scss";

.tooltip-course-header-section{
  color: #344054 !important;
  font-family:$font-family;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 18px !important;
}
       
.tooltip-propmt-text{
  color: #344054 !important;
  font-family: $font-family;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.custom-tooltip {
  background-color: #ffffff !important;
  border-radius: 12px !important;
  border: 2px solid var(--Brand-600, #7f56d9) !important;
  padding: 4px 8px !important;
  text-align: center;
  font-size: 12px !important;
  max-width: 300px !important;
  word-wrap: break-word !important;
}


.find-majors-main-page-section {

  .major-section {
    position: relative !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding: 5px;
    width: 100%;
    display: flex;
    flex-flow: column;

    .find-majors-page {
      display: flex !important;
      max-width: 100% !important;
      border-radius: 10px;
      background: #fff;
      border-left: 4px solid #5841d9 !important;
      margin-bottom: 1.786rem;
    }

    .collegegrouplogo-section {
      width: 5.786rem !important;
      padding: 20px;

      .collegegroup-img {
        width: 42px;
        height: 42px;
      }
    }

    .findmajors-content {
      width: 90%;

      .poweredby-text-section {
        .poweredby-text {
          color: #000;
          font-family: $font-family;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          position: relative;
        }
      }

      .choose-text-section {

        
        .choose-text {
          color: #000;
          font-family: $font-family;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          position: relative;

         
        }

        .second-choose-text {
          color: #000000 !important;
          font-family: $font-family;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: 24px !important;
          position: relative !important;

        }
      }
    }

    .select-categories-section {
      max-width: 100% !important;
      flex-shrink: 0;
      border-radius: 10px;
      border: 2px solid #417bd9;
      background: #fff;
    }

    .select-category-header {
      color: var(--Deep-Purple, #141627) !important;
      font-family: $font-family;
      font-size: 20px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 30px !important;
      padding: 15px;
    }



    .categories-mainselection-btns {
      display: flex !important;
      left: 25px !important;
      position: relative;
      flex-wrap: wrap;

      .category-button-wrapper {
        margin-bottom: 10px;
        margin-right: 0.786rem;
      }
    }

    .categories-btn-section {
      border-bottom: 2px solid #5841d9;
      position: relative !important;
    }

    .subcategories-border {
      top: 10px;
      position: relative;
      border-top: 2px solid #292f5d !important;
    }

    .subcategories-header {
      color: var(--Deep-Purple, #141627) !important;
      font-family: $font-family;
      font-size: 20px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 30px !important;
      padding: 16px;
    }

    .subcategories-section {
      display: flex !important;
      left: 25px !important;
      position: relative;
      flex-wrap: wrap;
    }

    .subCategory-button-wrapper {
      margin-bottom: 25px;
      margin-right: 0.786rem;
    }

    .selected-items-section {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .generate-majors-btn-section {
      margin-top: 12px;
      bottom: 1px;
      position: relative;

      padding-left: 25px;

      .selected-items-wrapper-total-section{
        color:  #808080 !important;
        font-family: $font-family;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        text-transform: none;
        line-height: 28px;
      }

      .selected-items-wrapper {
        display: flex;
        width: 70%;
        justify-content: space-between;

        .selected-items {
          display: flex;
          flex-wrap: wrap;
        }

        .selected-item-button-wrapper {
          margin-right: 15px !important;
          margin-bottom: 10px;
        }
      }

      .selected-items-wrapper-btn {
        display: flex;
        width: 30%;
        float: right;
        justify-content: right;

        .selected-items {
          display: flex;
          flex-wrap: wrap;
        }

        .selected-item-button-wrapper {
          margin-right: 15px !important;
          margin-bottom: 10px;
        }
      }

      .generate-major-btn {
        align-content: center;
        text-align: end !important;
        right: 10px;
        bottom: 5px;
        position: relative;
        flex-wrap: wrap;
      }

      .genarate-btn-text {
        color: var(--Gray-700, white);
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-transform: none;
        line-height: 20px;
        border-radius: 8px;
        border: 1px solid var(--Brand-600, #7f56d9);
        background: var(--Brand-600, #7f56d9);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding: 10px 16px;
      }

      .genarate-btn-text:hover {
        color: var(--Gray-700, white);
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-transform: none;
        line-height: 20px;
        border-radius: 8px;
        border: 1px solid var(--Brand-600, #7f56d9);
        background: var(--Purple-800, #4a1fb8);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding: 10px 16px;
      }

      .genarate-btn-text-disabled {
        color: var(--Gray-300, #d0d5dd);
        border: 1px solid var(--Gray-600, #475467);
        background: var(--Gray-700, #344054);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-transform: none;
        line-height: 20px;
        border-radius: 8px;
        padding: 10px 16px;

      }
    }
  }

  .stem-relatedstyles {
    background-color: white !important;
    border: 1.5px solid #027a48 !important;
    color: #027a48 !important;
    padding: 8px 18px;
    border-radius: 20px;
    width: 195px;
    height: 35px;
    text-transform: none;
    justify-content: center;
    align-items: center;
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .humanities-relatedstyles {
    background-color: white !important;
    border: 1.5px solid #5841d9 !important;
    color: #5841d9 !important;
    padding: 8px 18px !important;
    border-radius: 20px !important;
    width: 195px !important;
    height: 35px !important;
    text-transform: none !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: $font-family;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
  }

  .business-relatedstyles {
    background-color: white !important;
    border: 1.5px solid #ec4a0a !important;
    color: #ec4a0a !important;
    padding: 8px 18px !important;
    border-radius: 20px !important;
    width: 195px !important;
    height: 35px !important;
    text-transform: none !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: $font-family;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
  }

  .extra-relatedstyles {
    background-color: white !important;
    border: 1.5px solid #000000 !important;
    color: black !important;
    padding: 8px 18px !important;
    border-radius: 20px !important;
    width: 195px !important;
    height: 35px !important;
    text-transform: none !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: $font-family;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important;
  }

  .empty-content-section {
    height: calc(100% - 350px);
    position: initial !important;
  }

  .green-hover {
    border: 1px solid var(--Success-700, #027a48) !important;
    background: #d7fab5 !important;
  }

  .purple-hover {
    border: 1px solid var(--Purple, #5841d9) !important;
    background: var(--Purple-300, #bdb4fe) !important;
  }

  .blue-hover {
    border: 1px solid var(--Blue-light-600, #0086c9) !important;
    background: var(--Blue-light-300, #7cd4fd) !important;
  }

  .orange-hover {
    border: 1px solid var(--Blue-light-600, #c4320a) !important;
    background: var(--Orange-300, #fdb022) !important;
  }

  .green-select {
    border: 3px solid var(--Success-700, #027a48) !important;
    color: #027a48 !important;
    background: #d8eea2 !important;
  }

  .purple-select {
    border: 3px solid var(--Purple, #5841d9) !important;
    color: #5841d9 !important;
    background: #bdb4fe !important;
  }

  .blue-select {
    border: 3px solid var(--Blue-light-600, #0086c9) !important;
    color: #293056 !important;
    background: var(--Blue-light-300, #7cd4fd) !important;
  }

  .orange-select {
    border: 3px solid var(--Orange-600, #c4320a) !important;
    color: #c4320a !important;
    background: var(--Orange-300, #feb273) !important;
  }

  .max-err-msg {
    text-align: center;
  }

  .search-mode-align {
    text-align: end;
    margin-bottom: 0.786rem;
    color: var(--Deep-Purple, #141627);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    .search-mode-hover:hover{
      color: var(--Gray-700, white);
      border-radius: 8px;
      background: var(--Blue-gray-200, #D5D9EB);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    
    }
  }

}