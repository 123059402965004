@import "../../Variables.scss";

.login-main-grid-section {
  width: 90% !important;
  margin-left: 4% !important;
  display: flex !important;
}

.collecraft-login-form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2rem !important;
  margin-right: 6rem;

  .collecraft-login-form-contents {
    width: 75%;

    .header-section-aligmenent {
      align-self: stretch !important;
      margin-bottom: 0.786rem;
    }

    .login-header-section {
      color: var(--Gray-900, #101828);
      font-family: $font-family;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 44px;
      letter-spacing: -0.72px;
    }

    .welcomeback-text-section {
      align-self: stretch !important;
      margin-bottom: 1.486rem;

      .welcomeback-text {
        color: var(--Gray-500, #667085);
        font-family: $font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .login-form-email-field {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
      align-self: stretch;

      .text-element {
        display: flex;
        
        align-items: center;
        gap: 8px;
        align-self: stretch;

        .MuiInputBase-input {
          border-radius: 10px;
          border: 1px solid var(--Gray-300, #d0d5dd);
          background: var(--White, #fff);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
      }

      .Mui-error {
        color: #d32f2f;
        margin-left: 0rem;
      }
    }

    .login-form-password-field {
      color: var(--Gray-700, #344054);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;


      .text-element {
        height: 2rem;
        margin: 5px 1px 1.5rem 1px;

        .MuiInputBase-input {
         
          height: 0.2rem;
          color: var(--Gray-500, #667085);
          font-family: $font-family;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .Mui-error {
        color: #d32f2f;
        margin-left: 0rem;
      }
    }

    .remember-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      margin-top: 0.786rem;
      margin-bottom: 1rem;
      width: 100% !important;

      .checkbox-section {
        display: flex;
        justify-content: center;
        align-items: center;

        .remember-checkbox {
          border-radius: 4px;
          border: 1px solid var(--Gray-300, #d0d5dd);
          background: var(--White, #fff);
          width: 15px;
          height: 16px;
          margin-right: 0.412rem;
        }

        .remember-text {
          color: #344054;
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 10px;
        }
      }

      .login-forgot-password {
        .login-forgot-pwd-label {
          color: #6941c6;
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          text-decoration-line: underline;
          cursor: po$font-family;
        }
      }
    }

    .terms-conditions-checkbox-section {
      display: flex !important;
      align-items: center !important;
      margin-top: 0.786rem;
      margin-bottom: 1rem;
      width: 100% !important;
      text-align: justify !important;
      margin-left: 6rem;

      .terms-conditions-remember-checkbox {
        border-radius: 4px;
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--White, #fff);
        width: 15px;
        height: 15px;
        margin-right: 0.412rem;
      }

      .remember-text {
        color: var(--Gray-700, #344054);
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .terms-conditions-link {
        color: var(--Gray-700, #0a61f7) !important;
        font-family: $font-family;
        font-size: 14px;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 20px;
        text-decoration-line: underline;
      }
    }

    .sign-in-btn-align {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      .login-sign-btn {
        border-radius: 8px;
        border: 1px solid var(--Brand-600, #7f56d9);
        background: #4d41d9;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      .login-sign-btn-disable-mode {
        border-radius: 8px;
        border: 1px solid var(--Gray-600, #475467);
        background: var(--Gray-700, #344054);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #fff !important;
      }

      .login-sign-btn:hover {
        border-radius: 8px;
        border: 1px solid var(--Brand-600, #7f56d9);
        background: var(--Purple-800, #4a1fb8);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }

    .login-signup-btn-section {
      display: flex;
      padding: 10px 0px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      .login-signup-btn {
        border-radius: 8px;
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--White, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        text-transform: none;
      }

      .login-signup-btn-lable {
        color: var(--Gray-700, #344054);
        font-family: $font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .donthave-account-section {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 5px;
      align-self: stretch;
      text-align: center;
      margin-bottom: 2rem;

      .have-account {
        color: var(--Gray-500, #667085);
        font-family: $font-family;
        font-size: 15px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 20px;
      }

      .dont-have-account-signup {
        color: var(--Brand-700, #6941c6);
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        text-decoration-line: underline;
      }
    }

    .login-bottom-section {
      display: flex;
      width: 100% !important;
      margin: 1rem 0 1rem 0;
      justify-content: space-between;
      gap: 10px;
    }
  }
}

.login-api-error-message{
  height: 22px !important;
}

.custom-input-adornment {
  display: flex !important;
  height: 0.01em !important;
  max-height: 2em !important;
  align-items: center !important;
  white-space: nowrap !important;
  color: rgba(0, 0, 0, 0.54) !important;
  margin-left: -54px !important;
}
