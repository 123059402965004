@import "../../Variables.scss";

.manualsearch-ai-boxes-sections {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  flex: wrap !important;
  align-items: center;
  width: 100%;
  gap: 3rem;
}

.first-box-section,
.second-box-section {
  border: 2px solid #5841d9;
  border-radius: 10px;
  background-color: #ffffff;
  width: 45%;
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1024px) {
  .manualsearch-ai-boxes-sections {
    flex-direction: column !important;
  }

  .first-box-section,
  .second-box-section {
    width: 90%;
  }
}

.first-box-content-section,
.second-box-content-section {
  text-align: center;
  padding: 20px !important;

  .eye-section {
    margin-bottom: 0.186rem !important;
  }

  .btn-section {
    margin-bottom: 1rem;
  }

  .ai-search-first-header-section {
    margin-bottom: 0.486rem !important;
  }

  .ai-search-second-header-section {
    margin-bottom: 1rem;
  }

  .manualsearch-first-header-section {
    margin-bottom: 0.486rem !important;
  }

  .manualsearch-second-header-section {
    margin-bottom: 1rem;
  }

  .remember-me-text {
    margin-bottom: 1rem;
  }


}

.manualsearch-first-header-section {
  color: #141627;
  font-family: $font-family;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.ai-search-first-header-section {
  color: #141627;
  font-family: $font-family;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.ai-search-second-header-section {
  color: #000000;
  font-family: $font-family;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.manualsearch-second-header-section {
  color: #000000;
  font-family: $font-family;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.remember-me-text {
  color: #344054;
  font-family: $font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}


.remember-checkbox-container {
  display: inline-flex;
  margin-top: 0.186rem;

  .remember-checkbox {
    background: var(--White, #fff);
    width: 16px;
    height: 16px;
    margin-right: 0.412rem;
    top: -1.712px;
    position: relative;
  }

  .Mui-checked {
    color: #6941c6 !important;
  }

  .remember-text {
    color: var(--Gray-700, #344054);
    font-family: $font-family;
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.checkbox-screen {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  position: relative;
}

.checkbox-screen .checkbox-instance {
  flex: 0 0 auto !important;
}

.checkbox-screen .text-wrapper {
  color: var(--gray-700);
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}


.get-started-btn-section {

  .get-started-btn-text {
    color: var(--Gray-700, white);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: none;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid var(--Brand-600, #7f56d9);
    background: var(--Brand-600, #7f56d9);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 16px;
    align-content: center;
    text-align: end !important;
    right: 10px;
    bottom: 5px;
    position: relative;
    flex-wrap: wrap;
    width: 50% !important;
  }


  .get-started-btn-text:hover {
    color: var(--Gray-700, white);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: none;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid var(--Brand-600, #7f56d9);
    background: var(--Purple-800, #4a1fb8);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 16px;
  }


}