@import "../../Variables.scss";

.find-colleges-page {
  color: #000;
  font-family: $font-family;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
