@import "../../Variables.scss";

.major-overlay {
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0px);
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  
    .loader-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .hold-message {
      color: #141627 !important;
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
  
    }
  
    .rotating-loader {
      position: relative;
      animation: rotate 2s linear infinite;
    }
  
    .custom-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 45px;
      height: 45px;
    }
  
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }
  