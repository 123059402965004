@import "../../Variables.scss";

.generate-major-mainpage-section {
.generate-majors-page {
  display: flex !important;
  max-width: 100% !important;

  border-radius: 10px;
  background: #fff;
  border-left: 4px solid #5841d9 !important;
  margin-bottom: 1.786rem !important;
}

.collegegrouplogo-section {
  width: 4.786rem !important;
  padding: 20px;

  .collegegroup-img {
    width: 42px;
    height: 42px;
  }
}

.generate-majors-content {
  width: 90%;

  .generate-majors-poweredby-text-section {
    .generate-majors-poweredby-text {
      color: #000;
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      position: relative;
    }
  }
}

.generate-majors-selected-courses-section {
  max-width: 100% !important;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #414989;
  background: #fff;

  .matched-courses-header-section {
    display: flex !important;

    .findmajorslogo-section {
      width: 4.786rem !important;
      padding: 20px;
    }

    .generate-majors-header-details {
      margin-top: 1rem;

      .generate-majors-header-name {
        color: var(--Deep-Purple, #141627);
        font-family: $font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0.786rem;
      }

      .generate-majors-date-section {
        color: #667085;
        font-family: $font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}

.tags-and-pdf {
  display: flex !important;
  justify-content: space-between;
  
  margin-bottom: 1rem;
  width: 100%; 
 
}

.generate-majors-tags-section {
  margin-left: 1.486rem;
  color: var(--Deep-Purple, #141627);
  font-family: $font-family;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.generat-major-savepdf-button-section {
  margin-right: 2.21rem;
  bottom: 12px;
  position: relative;

  .savepdf-button:hover{
    border: 1px solid var(--Brand-600, #62c3e0);
  }
  .savepdf-button-disabled{
    border-radius: 8px !important;
    border: 1px solid var(--Gray-600, #475467) !important;
    background: var(--Gray-700, #344054) !important;
    color: var(--Gray-700, white);
    font-family: $font-family;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    text-transform: none !important;
    line-height: 20px !important;
    padding: 10px 16px !important;     
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    text-transform: none !important;
  }

  .savepdf-button {
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Gray-700, #344054);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 12px 16px;
    height: 42px;
    text-decoration: none !important;
    text-transform: none !important;
  }

  .savepdf-button-image {
    position: relative !important;
    right: 4px;
  }

  .savepdf-button-disabled-image{
    position: relative !important;
    right: 3px;
    filter: brightness(0) invert(1);
  }
}






.generate-majors-selected-list-of-courses-first-section {
  border-radius: 4px;
  border: 1px solid var(--Brand-300, #d6bbfb);
  background: var(--White, #fff);
  max-width: 95%;
  max-height: 100%;
  flex-wrap: wrap;
  margin-left: 1.686rem;
  margin-bottom: 0.786rem;

  .major-options {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    width: 100%;
  }
}

.generate-majors-selected-list-of-courses-first-section-content {
  display: flex;
  padding: 10px;
  width: 100%;
}

.generate-majors-course-log {
  margin-right: 0.786rem;
}

.generate-majors-selected-course-logo-section {
  flex-shrink: 0;

  .findmajors-logo-align {
    width: 75px;
    text-align: center;
    margin-top: 0.106rem;
  }
}

.generate-majors-selected-course-content-section {
  flex: 1;
}

.generate-majors-selected-course-name {
  color: #141627 !important;
  font-family: $font-family;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.generate-majors-selected-course-description {
  color: #667085 !important;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 0.786rem;
 
}


.generate-majors-selected-course-home-barchat-persons-section {
  display: flex;
  flex: wrap;
  width: 100%;
}

.home-section {
  display: flex;
  width: 33.33%;
  flex: wrap !important;

  .generate-majors-homeicon-section {
    margin-right: 0.786rem;
    margin-top: 0.21rem;
  }

  .generate-majors-selected-course-home-info-section {
    .generate-majors-selected-course-home-info {
      color: var(--Deep-Purple, #141627);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .generate-majors-selected-course-home-info-down-content {
      color: var(--Gray-500, #667085);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.barchat-section {
  display: flex;
  width: 30%;
  flex: wrap !important;

  .generate-majors-barchat-section {
    margin-right: 0.786rem;
    margin-top: 0.21rem;
  }

  .generate-majors-selected-course-barchat-info-section {
    .generate-majors-selected-course-barchat-info {
      color: var(--Deep-Purple, #141627);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .generate-majors-selected-course-barchat-info-down-content {
      color: var(--Gray-500, #667085);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.persons-section {
  display: flex;
  width: 33.33%;
  flex: wrap !important;

  .generate-majors-persons-section {
    margin-right: 0.786rem;
    margin-top: 0.21rem;
  }

  .generate-majors-selected-course-persons-info-section {
    .generate-majors-selected-course-persons-info {
      color: var(--Deep-Purple, #141627);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .generate-majors-selected-course-persons-info-down-content {
      color: var(--Gray-500, #667085);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.generate-majors-action-btns-section {
  display: flex;
  align-content: center;
  flex-wrap: wrap !important;
  margin-left: 50px !important;

  .generate-majors-downthumbsup {
    position: relative;
    top: 4px;
  }

  .generate-majors-upthumbsup {
    position: relative;
    top: 4px;
    margin-right: -1.58rem;
  }

  .fav-btn {
    display: flex;

    .generate-majors-favorites-btn-section {
      width: 100px;
      margin-right: 0.786rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 8px;
      height: 35px;

      border: 1px solid var(--Lime---Dark, #d8eea2);
      background: var(--Green-Gradient, #e6fab5);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      .loveicon-space {
        margin-right: 0.286rem;
      }

      .generate-majors-favorites-btn {
        color: var(--Gray-700, #344054);
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-transform: capitalize;
      }
    }

    .generate-majors-learn-more-btnsection {
      display: flex;
      justify-content: center;

      height: 35px;

      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid var(--Brand-600, #7f56d9);
      background: var(--Purple, #5841d9);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      .generate-majors-learn-more-btn {
        color: var(--White, #fff);
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        text-transform: capitalize;
      }
    }
  }
}

.MuiDrawer-paper {
  box-sizing: border-box !important;
  width: 240px !important;
  background: #141627 !important;
}

.generate-majors-drawer-section-bnts {
  display: flex;
  top: 10px;
  position: relative;

  .generate-majors-drawer-arraw-section {
    width: 64%;
  }

  .generate-majors-drawer-favorites-section {
    margin-right: 0.786rem;
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    height: 35px;

    border: 1px solid var(--Lime---Dark, #d8eea2);
    background: var(--Green-Gradient, #e6fab5);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .generate-majors-drawer-loveicon-space {
      margin-right: 0.286rem;
    }

    .generate-majors-drawer-favorites-btn {
      color: var(--Gray-700, #344054);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}

.generate-major-drawer-divider {
  background: #292f5d !important;
  width: 364px;
  height: 1.5px;
  margin-top: 20px !important;
}

.learn-more-section{
    padding: 20px;
    margin-top: 0.76rem;
    .about-major-header, .about-notablepeople-header {
      color: var(--White, #fff) !important;
      font-family: $font-family;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      bottom: 4px;
      position: relative;
    }
    .about-major-description{
      color: var(--Gray-300, #D0D5DD) !important;
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; 
      margin-bottom: 0.786rem;
    }
  
  
  .about-notablepeople-description {
   
    .notable-people-description {
      color: var(--Gray-300, #D0D5DD) !important;
      font-family: $font-family;
      font-size: 16px;
      align-self: stretch;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0.786rem;
    }

  }
}



.generate-major-drawer-histroy-section {
  height: auto;

  .generate-major-drawer-histroy-content {
    display: flex;
    padding: 11px !important;
  }

  .generate-major-drawer-bookopen {
    flex-shrink: 0;
    margin-right: 0.786rem;
   
  }

  .open-book {
    width: 40px !important;
  }

  .generate-majors-drawer-historyhome-section {
    display: flex !important;
    padding: 11px !important;

    .generate-majors-drawer-historyhome {
      margin-right: 0.786rem;
    }

    .generate-majors-drawer-historyhome-info {
      color: #fff;
      font-family: $font-family; 
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .generate-majors-drawer-historyhome-info-down-content {
      color: var(--Gray-400, #98a2b3);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .generate-majors-drawer-history-header {
    color: #fff;
    font-family: $font-family;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .generate-majors-drawer-history-description {
    color: var(--Gray-400, #98a2b3);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.generate-major-drawer-scroll {
  max-height: calc(100vh - 135px) !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.no-records-section {
  width: 100%;
  margin-top: 5rem !important;
  margin-bottom: 14rem !important;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #414989;
  background: #fff;

  .span-section {
    padding-top: 1rem;
    font: normal normal 18px/20px Open Sans, Sans-serif;
    letter-spacing: 0px;
    color: #2f353f;
    margin: 3px;
    height: 3rem;
    text-align: center;
  }
}
}



