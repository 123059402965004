@import "../Variables.scss";

.signup-college-application-header {
  font-family: $font-family !important;
  display: flex !important;
  width: 235.444px !important;
  height: 72px !important;
  align-items: flex-start !important;
  gap: 7.223px !important;

  .signup-collegecraft-headername {
    color: #000 !important;
    font-family: $font-family !important;
    display: flex !important;
    height: 75px !important;
    padding: 38px !important;
    align-items: center !important;
    align-self: stretch !important;
    font-size: 1.87556rem !important;
    font-style: normal !important;
    font-weight: 900 !important;
    line-height: normal !important;

    .signup-collgecrafticon {
      margin-right: 0.386rem !important;
      margin-left: -2rem !important;
    }
  }
}
