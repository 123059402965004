@import "../../Variables.scss";

.collogecrafteremail-text {
  color: #667085 !important;
  font-family: $font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
