.overlay {
  position: fixed;
  top: 0;
  left: 120px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0px);
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
