@import "../Variables.scss";

.college-application-header {
  font-family: $font-family !important;
  display: flex;
  width: 235.444px;
  height: 99px;
  align-items: flex-start;
  gap: 7.223px;

  .collegecraft-headername {
    color: #000;
    font-family: $font-family !important;
    display: flex;
    height: 96px;
    padding: 38px;
    align-items: center;
    align-self: stretch;
    font-size: 1.87556rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;

    .collgecrafticon {
      margin-right: 0.386rem;
      margin-left: -2rem;
    }
  }
}
