@import "../../Variables.scss";

.findMajors-Manual-Search-Page{

  .manual-search-page {
    display: flex !important;
    max-width: 100% !important;
    border-radius: 10px;
    background: #fff;
    border-left: 4px solid #5841d9 !important;
    margin-bottom: 1.786rem;
  }

  .manual-search-mode-align{
    text-align: end;
    height: 46vh;
  }
  

  

  .collegegrouplogo-section {
    width: 5.786rem !important;
    padding: 20px;

    .collegegroup-img {
      width: 42px;
      height: 42px;
    }
  }

  .manual-search-content {
    width: 90%;

    .poweredby-text-section {
      .poweredby-text {
        color: #000;
        font-family: $font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        position: relative;

       
      }
    }

    .choose-text-section {
      .choose-text {
        color: #000;
        font-family: $font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        position: relative;

        
      }
      .second-choose-text {
        color: #000000 !important;
        font-family: $font-family;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        position: relative !important;

       
      }
    }
  }

  .manual-search-field{
    border-radius: 10px !important;
    border: #5841D9 !important;
  .generate-major-btn {
    align-content: center;
    text-align: end !important;
    right: 10px;
    position: relative;
    flex-wrap: wrap;
  }
  
  .genarate-btn-text {
    color: var(--Gray-700, white);
 font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    text-transform: none;
    line-height: 20px;
    border-radius: 8px;
    border: 1px solid var(--Brand-600, #7f56d9);
    background: var(--Brand-600, #7f56d9);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px 16px;
  }

  .genarate-btn-text-disabled{
    color: var(--Gray-300, #d0d5dd) !important;
    border: 1px solid var(--Gray-600, #475467) !important;;
    background: var(--Gray-700, #344054) !important;;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;;
 font-family: $font-family;
    font-size: 14px !important;;
    font-style: normal !important;;
    font-weight: 600 !important; ;
    text-transform: none !important;;
    line-height: 20px !important;;
    border-radius: 8px !important;;
    padding: 10px 16px !important;;
  }
  
  .genarate-btn-text:hover {
      color: var(--Gray-700, white);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-transform: none;
      line-height: 20px;
      border-radius: 8px;
      border: 1px solid var(--Brand-600, #7f56d9);
      background: var(--Purple-800, #4a1fb8);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 10px 16px;
  }
  
    .manual-search-field-generate-major-input-field{
      width: 100% !important;
      
      .Mui-focused {
        border-radius: 10px;
        border: 2px solid #5841D9;
        background: var(--White, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        .MuiOutlinedInput-notchedOutline {
          border: none;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
      }
    
      .MuiInputBase-input {
        color: black !important;
        font-family: $font-family;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }


  .chip-text-aligment{
    width: 100%;
    color: var(--Deep-Purple, #141627) !important;
    font-family: $font-family;
    font-size: 16px !important ;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 24px !important; 
    border-radius: 60px !important;
    border: 1px solid #BBB !important;
    background: rgba(231, 231, 231, 0.49) !important;
  }

  
  
  

  .suggested-courses-section{
    left: 3px;
    position: relative;
    .suggested-header{
      color: var(--Deep-Purple, #141627);
      font-family: $font-family;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .suggestion-list{
      color:  #141627 !important;
      font-family: $font-family;
      font-size: 16.212px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      cursor: pointer !important;
      margin: 0 2px;
    }
  }

  .max-err-msg{
    text-align: center !important;
  }

  .manual-search-mode-align{
    text-align: end;
    margin-bottom: 0.786rem;
    color: var(--Deep-Purple, #141627);
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    .search-mode-hover:hover{
      color: var(--Gray-700, white);
      border-radius: 8px;
      background: var(--Blue-gray-200, #D5D9EB);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    
    }
  }
}
