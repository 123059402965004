@import "../../Variables.scss";

.forgot-pwd-main-grid-section {
  width: 88% !important;
  margin-left: 4% !important;
  display: flex !important;
}

.header-section {
  text-align: justify !important;
  margin-bottom: 3rem;
}

.collegecrafter-app-header {
  color: #000;
  font-family: $font-family;
  font-size: 24.671px !important;
  font-style: normal;
  font-weight: 900 !important;
  line-height: normal;
  display: flex;
  height: 93px;
  padding: 40px;
  align-items: center;
  align-self: stretch;
}

.forgot-pwd-form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2rem;
  float: left;
  margin-right: 6rem;

  .forgot-pwd-header-part {
    align-self: stretch !important;
  }

  .forgot-pwd-header-section {
    color: var(--Gray-900, #101828);
    font-family: $font-family;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.72px;
  }

  .forgot-pwd-welcomeback-text {
    color: var(--Gray-500, #667085);
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .forgot-pwd-form-content {
    width: 75%;

    .text-element {
      height: 2rem;

      .MuiInputBase-input {
        height: 0.2rem;
        color: var(--Gray-500, #667085);
        font-family: $font-family;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .forgot-pwd-form-fields {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1px;
      height: 68px;
      margin-top: 0.786rem;
      align-self: stretch;

      .css-1wc848c-MuiFormHelperText-root.Mui-error {
        color: #d32f2f;
        margin-left: 0rem;
      }

      .forgot-pwd-email-header,
      .forgot-pwd-password-header,
      .forgot-pwd-retypepassword-header {
        color: var(--Gray-700, #344054);
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: -0.786rem;
      }
    }

    .forgot-pwd-complexity-message {
      color: var(--Gray-500, #667085);
      font-family: $font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: -0.5rem;
    }

    .remember-container {
      display: flex;
      align-items: center;
      margin-top: 0.786rem;
      margin-left: -0.186rem;
      align-self: flex-start !important;

      .remember-checkbox {
        border-radius: 4px;
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--White, #fff);
        width: 16px;
        height: 16px;
        margin-right: 0.412rem;
      }

      .remember-text {
        color: var(--Gray-700, #344054);
        font-family: $font-family;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .save-btn-section {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;

      .save-btn {
        border-radius: 8px;
        border: 1px solid var(--Brand-600, #7f56d9);
        background: #4d41d9;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      .save-btn-disabled-mode {
        border-radius: 8px;
        border: 1px solid var(--Gray-600, #475467);
        background: var(--Gray-700, #344054);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: #fff !important;
      }

      .save-btn:hover {
        border-radius: 8px;
        border: 1px solid var(--Brand-600, #7f56d9);
        background: var(--Purple-800, #4a1fb8);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }

    .already-have-account {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 5px !important;
      align-self: stretch;
      margin-top: 0.786rem;

      .login-section {
        display: flex;
        color: var(--Gray-500, #667085);
        font-family: $font-family;
        font-size: 14px;
        gap: 5px !important;

        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;

        .forgot-pwd-login-label {
          color: var(--Brand-700, #6941c6);
          font-family: $font-family;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          text-decoration-line: underline;
          cursor: pointer;
          display: flex;
          align-items: flex-start;
        }
      }
    }

    .forgotpwd-bottom-section {
      display: flex;
      width: 100% !important;
      margin: 1rem 0 1rem 0;
      justify-content: space-between;
    }
  }
}

.forgot-api-error-message{
  height: 20px !important;
}

.f-custom-input-adornment {
  display: flex !important;
  height: 0.01em !important;
  max-height: 2em !important;
  align-items: center !important;
  white-space: nowrap !important;
  color: rgba(0, 0, 0, 0.54) !important;
  margin-left: -54px !important;
}
.fr-custom-input-adornment {
  display: flex !important;
  height: 0.01em !important;
  max-height: 2em !important;
  align-items: center !important;
  white-space: nowrap !important;
  color: rgba(0, 0, 0, 0.54) !important;
  margin-left: -54px !important;
}
